
import {Vue, Component, Watch} from 'vue-property-decorator';
import SayfaAciklama from "@/components/SayfaAciklama.vue";
import * as XLSX from 'xlsx';
import {LogEntity} from "@/entity/LogEntity";
import TopluKartEkleme from "@/classes/topluTakip/TopluKartEkleme";
import BorcluBilgileri from "@/classes/topluTakip/BorcluBilgileri";
import AlacakliBilgileri from "@/classes/topluTakip/AlacakliBilgileri";
import DosyaBilgileri from "@/classes/topluTakip/DosyaBilgileri";
import AlacakKalemiBilgileri from "@/classes/topluTakip/AlacakKalemiBilgileri";
interface ExcelHeader {
  field: string;
  callback?: (value: any) => any;
}

@Component({
  components: {SayfaAciklama}
})
export default class TopluExcelTakip extends Vue {
  downloading = false;

  excelFile: File | null = null;
  listTable = false
  tooltipInfo = false
  disableTopluTakip = false
  durumTable = false
  fileName: string = "";
  items: any[] = [];
  eksikAlanlar: any = [null]
  kontrolItems: any = [];
  excelHeaders: any= {};
  excelItems = [{}]
  raporAdi= 'ornekExcel'

  downloadExcel() {
    this.downloading = true;
    this.$helper
        .download(
            "OrnekExcel.xlsx",
            "/api/v1/excel-aktar-ornek-file"
        )
        .finally(() => {
          this.downloading = false;
        });
  }
  mounted(){
    this.headers.forEach((item:any) => {
      if (item.text!=='KALDIR' && item.text!=='DURUM'){
        this.excelHeaders[item.text] = item.value;
      }
    });
  }
/*
  kontrolHeaders = [
    {text: "TEKİL ALAN", value: "tekil_alan"},
    {text: "TAKİP TİPİ", value: "takip_tipi"},
    {text: "TAKİP ALT TİPİ", value: "takip_alt_tipi"},
    {text: "TAKİP YOLU", value: "takip_yolu"},
    {text: "DURUM", value: "status"},
    {text: "KALDIR", value: "remove"},
  ];
*/

  headers:any = [
    {text: "KALDIR", value: "remove"},
    {text: "DURUM", value: "status"},
    {text: "TEKİL ALAN", value: "tekil_alan"},
    {text: "TAKİP TİPİ", value: "takip_tipi"},
    {text: "TAKİP YOLU", value: "takip_yolu"},
    {text: "TAKİP TARİHİ", value: "takip_tarihi"},
    {text: "TAKİP MAHİYETİ", value: "takip_mahiyeti"},
    {text: "İCRA DAİRESİ", value: "icra_dairesi"},
    {text: "ESAS NO", value: "esas_no"},
    {text: "ALACAKLI KURUM/KİŞİ", value: "alacakli_kurum_mu"},
    {text: "ALACAKLI AD SOYAD", value: "alacakli_ad"},
    {text: "ALACAKLI KURUM İSİM", value: "alacakli_isim"},
    {text: "ALACAKLI VERGİ/TC NO", value: "alacakli_tc_vergi"},
    {text: "BORÇLU AD SOYAD", value: "borclu_ad"},
    {text: "BORÇLU KURUM İSİM", value: "borclu_isim"},
    {text: "BORÇLU CİNSİYET", value: "borclu_cinsiyet"},
    {text: "BORÇLU ADRESİ", value: "borclu_adres_bilgisi"},
    {text: "BORÇLU İL", value: "borclu_il"},
    {text: "BORÇLU İLÇE", value: "borclu_ilce"},
    {text: "BORÇLU TÜRÜ ŞAHIS ŞİRKET", value: "borclu_kurum_mu"},
    {text: "BORÇLU CEP TELEFON", value: "cepTelefon"},
    {text: "BELGE TUTARI", value: "tutar"},
    {text: "VADE TARİHİ", value: "vade_tarihi"},
    {text: "FAİZ BAŞLANGIÇ TARİHİ", value: "faiz_baslangic_tarihi"},
    {text: "ALACAK AÇIKLAMASI", value: "alacak_aciklama"},
    {text: "TAKİP ÖNCESİ FAİZ TÜRÜ", value: "takip_oncesi_faiz_turu"},
    {text: "TAKİP SONRASI FAİZ TÜRÜ", value: "takip_sonrasi_faiz_turu"},
    {text: "ELLE FAİZ Mİ", value: "elle_faiz_mi"},
    {text: "ORAN/TUTAR", value: "elle_faiz_tutar_mi"},
    {text: "İŞLEYEN FAİZ", value: "elle_faiz_tutari"},
    {text: "YILLIK ORAN", value: "elle_faiz_tutari_yillik"},

  ];

  removeItem(itemToRemove: any) {
    const indexToRemove = this.items.indexOf(itemToRemove);
    if (indexToRemove !== -1) {
      this.items = this.items.filter((item: any) => item !== itemToRemove);
    }
    if (this.eksikAlanlar[indexToRemove]) {
      this.eksikAlanlar.splice(indexToRemove, 1);
    }
  }

  listeleClick() {
    if (this.excelFile) {
      this.listTable = true;
      this.parseExcelData()
    }
  }

  splitBorcluAdSoyad(adSoyad: string): {ad: string, soyad: string} {
    if (!adSoyad) return {ad: '', soyad: ''};

    const trimmed = adSoyad.trim();
    const lastSpaceIndex = trimmed.lastIndexOf(' ');

    if (lastSpaceIndex === -1) {
      return {ad: trimmed, soyad: ''};
    }

    return {
      ad: trimmed.substring(0, lastSpaceIndex),
      soyad: trimmed.substring(lastSpaceIndex + 1)
    };
  }
  splitAlacakliAdSoyad(adSoyad: string): {alacakli_ad: string, alacakli_soyad: string} {
    if (!adSoyad) return {alacakli_ad: '', alacakli_soyad: ''};

    const trimmed = adSoyad.trim();
    const lastSpaceIndex = trimmed.lastIndexOf(' ');

    if (lastSpaceIndex === -1) {
      return {alacakli_ad: trimmed, alacakli_soyad: ''};
    }

    return {
      alacakli_ad: trimmed.substring(0, lastSpaceIndex),
      alacakli_soyad: trimmed.substring(lastSpaceIndex + 1)
    };
  }


  topluTakipAc() {
    this.durumTable=true

    const groupedItems: { [key: string]: any[] } = {};

    this.items.forEach((item: any) => {
      const tekilAlan = item.tekil_alan;
      if (!groupedItems[tekilAlan]) {
        groupedItems[tekilAlan] = [];
      }
      groupedItems[tekilAlan].push(item);
    });

    Object.keys(groupedItems).forEach((tekilAlan) => {
      const itemsForTekilAlan = groupedItems[tekilAlan];

      const topluKartEkleme = new TopluKartEkleme();

      topluKartEkleme.dosyaBilgileri = new DosyaBilgileri();

      itemsForTekilAlan.forEach((item) => {
        const borcluBilgisi = new BorcluBilgileri();
        const alacakliBilgisi = new AlacakliBilgileri();
        const alacakKalemleriBilgisi = new AlacakKalemiBilgileri();

        const {ad, soyad} = this.splitBorcluAdSoyad(item.borclu_ad);
        borcluBilgisi.ad = ad;
        borcluBilgisi.soyad = soyad;
        borcluBilgisi.isim = item.borclu_isim;
        borcluBilgisi.cinsiyet = item.borclu_cinsiyet;
        borcluBilgisi.adres_bilgisi = item.borclu_adres_bilgisi;
        borcluBilgisi.adres_turu = 'YURT İÇİ İKAMET';
        borcluBilgisi.il = item.borclu_il;
        borcluBilgisi.ilce = item.borclu_ilce;
        borcluBilgisi.kurum = item.borclu_kurum_mu;
        borcluBilgisi.cepTelefon = item.cepTelefon;

        topluKartEkleme.borcluBilgileri.push(borcluBilgisi);

        // Dosya Bilgileri sadece bir kez atanmalı (gruptaki ilk item'den alınabilir)
        if (topluKartEkleme.dosyaBilgileri.tekil_alan === undefined) {
          topluKartEkleme.dosyaBilgileri.takip_tipi = item.takip_tipi;
          topluKartEkleme.dosyaBilgileri.takip_alt_tipi = 'GENEL';
          topluKartEkleme.dosyaBilgileri.takip_yolu = item.takip_yolu;
          topluKartEkleme.dosyaBilgileri.takip_tarihi = item.takip_tarihi;
          topluKartEkleme.dosyaBilgileri.tekil_alan = item.tekil_alan;
          topluKartEkleme.dosyaBilgileri.takip_mahiyeti = item.takip_mahiyeti;
          topluKartEkleme.dosyaBilgileri.icra_dairesi = item.icra_dairesi;
          topluKartEkleme.dosyaBilgileri.esas_no = item.esas_no;
        }

         //Alacaklı Bilgileri
        const {alacakli_ad, alacakli_soyad} = this.splitAlacakliAdSoyad(item.alacakli_ad);
        alacakliBilgisi.alacakli_isim=item.alacakli_isim;
        alacakliBilgisi.alacakli_tc_vergi=item.alacakli_tc_vergi;
        alacakliBilgisi.alacakli_ad=alacakli_ad;
        alacakliBilgisi.alacakli_soyad=alacakli_soyad;
        alacakliBilgisi.alacakli_kurum_mu=item.alacakli_kurum_mu;
        topluKartEkleme.alacakliBilgileri.push(alacakliBilgisi);

        //Alacak Kalemi Bilgileri
        alacakKalemleriBilgisi.belge_turu='DİĞER';
        alacakKalemleriBilgisi.tutar=item.tutar;
        alacakKalemleriBilgisi.vade_tarih=item.vade_tarihi;
        alacakKalemleriBilgisi.faiz_baslangic_tarih=item.faiz_baslangic_tarihi;
        alacakKalemleriBilgisi.alacak_aciklamasi=item.alacak_aciklama;
        alacakKalemleriBilgisi.takip_oncesi_faiz_turu=item.takip_oncesi_faiz_turu;
        alacakKalemleriBilgisi.takip_sonrasi_faiz_turu=item.takip_sonrasi_faiz_turu==0?null:item.takip_sonrasi_faiz_turu;
        alacakKalemleriBilgisi.elle_faiz_mi=item.elle_faiz_mi==='EVET'?true:false;
        alacakKalemleriBilgisi.elle_faiz_tutar_mi=item.elle_faiz_tutar_mi==='TUTAR'?true:false;
        alacakKalemleriBilgisi.elle_faiz_tutari=item.elle_faiz_tutari;
        alacakKalemleriBilgisi.elle_faiz_tutari_yillik=item.elle_faiz_tutari_yillik;
        topluKartEkleme.alacakKalemiBilgileri.push(alacakKalemleriBilgisi);
      });


      this.$http.post('/api/v1/job', {
        args:[topluKartEkleme],
        status: "WAITING",
        type_id: 5
      }).finally(()=>{
        this.$toast.success("Excel Takip Açma Başarıyla Gerçekleşmiştir.");
      });
    });



    this.eksikAlanlar.forEach((element:any) => {
      if (element!==null) {
        this.disableTopluTakip=true
        this.tooltipInfo = true
      }else{
        this.disableTopluTakip=false
        this.tooltipInfo = false
      }
    })

  }


  @Watch('excelFile')
  changeExcelFile() {
    if (this.excelFile == null) {
      this.listTable = false
      this.disableTopluTakip = false
      this.tooltipInfo = false
      this.items = [];
      this.kontrolItems = [];
    }
  }


  async parseExcelData() {
    if (!this.excelFile) {
      console.error("No file selected.");
      return;
    }
    this.eksikAlanlar = {}

    if (!this.headers || this.headers.length === 0) {
      console.error("Headers are not defined.");
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(this.excelFile);

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;

      if (!arrayBuffer) {
        console.error("Failed to read the file content.");
        return;
      }

      try {
        const workbook = XLSX.read(arrayBuffer, {type: 'array'});
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        if (jsonData.length > 0) {
          this.items = [];

          jsonData.forEach((row: any) => {
            const item: any = {};

            this.headers.forEach((header:any) => {
              const normalizedHeader = header.text.toLowerCase().replace(/\s+/g, "").trim();
              const matchingKey = Object.keys(row).find(key =>
                  key.toLowerCase().replace(/\s+/g, "").trim() === normalizedHeader
              );

              if (matchingKey) {
                let cellValue = row[matchingKey];


                if (header.value === 'faiz_baslangic_tarihi' ||
                    header.value === 'vade_tarihi' ||
                    header.value === 'takip_tarihi' || header.value === 'donem_baslama_tarih' && typeof cellValue === 'number') {

                  const excelDate = new Date((cellValue - 25569) * 86400 * 1000);


                  cellValue = `${excelDate.getDate().toString().padStart(2, '0')}/${(excelDate.getMonth() + 1).toString().padStart(2, '0')}/${excelDate.getFullYear()}`;
                  // Tarih değerlerindeki gereksiz boşlukları ve \n karakterlerini temizle
                  if (typeof cellValue === 'string') {
                    cellValue = cellValue.replace(/\s+/g, '').replace(/\n/g, '').trim();
                  }
                }



                item[header.value] = cellValue;
              } else {
                item[header.value] = '';
              }

            });
            this.items.push(item);
          });

          this.items.forEach((item: any, index: any) => {
            let faizTuruDolu = item.elle_faiz_mi !== "" && item.elle_faiz_mi !== null;
            let faizTuruElleFaiz = item.elle_faiz_mi === "EVET";
            let oranTutarOran = item.elle_faiz_tutar_mi === "ORAN";
            let oranTutarTutar = item.elle_faiz_tutar_mi === "TUTAR";



            this.headers.forEach((header: any) => {

              // "kaldır" ve "durum" alanlarını kontrol etme
              if (header.value === "remove" || header.value === "status" ||header.value === "cepTelefon" ) {
                return; // Bu alanları atla
              }

              // Eksik alan kontrolü



              if (!item.hasOwnProperty(header.value) || item[header.value] === "" || item[header.value] === null) {

                if (item.borclu_kurum_mu === "KURUM") {
                  if (header.value === 'borclu_ad' || header.value === 'borclu_soyad' || header.value === 'borclu_cinsiyet') {
                    return; // Bu alanları atla
                  }
                }

                // BORÇLU TÜRÜ ŞAHIS ŞİRKET değeri "KİŞİ" ise, borçlu_isim alanını atla
                if (item.borclu_kurum_mu === "KİŞİ") {
                  if (header.value === 'borclu_isim') {
                    return; // Bu alanı atla
                  }
                }
                if (item.alacakli_kurum_mu === "KURUM") {
                  if (header.value === 'alacakli_ad' || header.value === 'alacakli_soyad') {
                    return; // Bu alanı atla
                  }
                }
                if (item.alacakli_kurum_mu === "KİŞİ") {
                  if (header.value === 'alacakli_isim') {
                    return; // Bu alanı atla
                  }
                }

                // Elle Faiz ve Oran seçilmişse
                if (faizTuruElleFaiz && oranTutarOran) {
                  if ( header.value === 'elle_faiz_tutari_yillik') {
                    if (!this.eksikAlanlar[index]) {
                      this.eksikAlanlar[index] = [header.text];
                    } else {
                      this.eksikAlanlar[index].push(header.text);
                    }
                  }
                }

                // Faiz türü boşsa yani Elle Faiz girilmemişse hata atmasın
                else if (!faizTuruDolu){
                  return
                }
                // Elle Faiz ve Tutar seçilmişse
                else if (faizTuruElleFaiz && oranTutarTutar) {
                  if (header.value === 'elle_faiz_tutari') {
                    if (!this.eksikAlanlar[index]) {
                      this.eksikAlanlar[index] = [header.text];
                    } else {
                      this.eksikAlanlar[index].push(header.text);
                    }
                  }
                }
                // Yasal Faiz seçilmişse, belirli alanlar zorunlu değil
                else if (item.elle_faiz_mi === "Yasal Faiz") {
                  if (
                      header.value === 'elle_faiz_tutari_yillik' ||
                      header.value === 'elle_faiz_tutar_mi' ||
                      header.value === 'elle_faiz_tutari'
                  ) {
                    return; // Bu alanları atla
                  } else {
                    if (!this.eksikAlanlar[index]) {
                      this.eksikAlanlar[index] = [header.text];
                    } else {
                      this.eksikAlanlar[index].push(header.text);
                    }
                  }
                }
                else if (item.borclu_adres_turu && (item.borclu_adres_turu.toLowerCase() === "mernis" || item.borclu_adres_turu.toLowerCase() === "mersis")) {
                  if (header.value === 'borclu_il' || header.value === 'borclu_ilce') {
                    return; // Bu alanları atla
                  } else {
                    if (!this.eksikAlanlar[index]) {
                      this.eksikAlanlar[index] = [header.text];
                    } else {
                      this.eksikAlanlar[index].push(header.text);
                    }
                  }
                }

                // Diğer durumlar için genel kontrol
                else {
                  if (!this.eksikAlanlar[index]) {
                    this.eksikAlanlar[index] = [header.text];
                  } else {
                    this.eksikAlanlar[index].push(header.text);
                  }
                }
              }
            });
          });

          console.log("Parsed items:", this.items);

        } else {
          console.error("Excel file is empty or not parsed correctly.");
        }
      } catch (error) {
        console.error("Error parsing Excel file: ", error);
      }
    };

    reader.onerror = (error) => {
      console.error("File reading error: ", error);
    };
  }


  fileRule(file: File): boolean | string {
    if (file) {
      let uzanti = file.name.split(".").pop();
      if (["xlsx", "XLSX", "xls", "XLS"].indexOf(uzanti!) >= 0) return true;
      else return "Sadece excel dosya yüklenebilir.";
    } else return "Lütfen bu alanı doldurun.";
  }

  onFileChange(file: File) {
    if (file) {
      this.fileName = file.name;
    }
  }
}
